<template>
  <CContainer>
    <CRow class="justify-content-center mt-4">
      <CCol md="12" class="text-start">
        <CRow>
          <CCol class="d-flex">
            <div class="v3-module-list-title">Kiosk</div>
            <HelpCenterButton classes="ms-2" content-key="module_kiosk" />
          </CCol>
          <CCol class="d-flex flex-row-reverse align-items-center">
            <CustomSwitch
              type="radio"
              test-id="kiosk-switch"
              :model-value="state.isActive"
              @change="toggleKiosk(state.isActive)"
            ></CustomSwitch>
          </CCol>
        </CRow>
        <div class="v3-module-list-box" v-if="state.isActive">
          <div class="d-flex flex-column">
            <div class="v3-module-list-checkbox d-flex">
              <CustomCheck
                v-model="state.form.urlKloginMethodOnly"
                label="Enable URL Klogin Method Only"
                @change="submit"
              ></CustomCheck>
            </div>
            <span class="small ms-4">
              (highly recommended for use with Chromebooks)</span
            >
          </div>
          <div class="d-flex flex-column">
            <div class="v3-module-list-checkbox d-flex mt-2">
              <CustomCheck
                v-model="state.form.cardAsDefaultLogin"
                label="Card As Default Login"
                @change="checkCard"
              ></CustomCheck>
            </div>
            <span class="small ms-4"> (Magnetic strip scanning) </span>
          </div>
          <div class="d-flex flex-column">
            <div class="v3-module-list-checkbox d-flex mt-2">
              <CustomCheck
                v-model="state.form.barcodeAsDefaultLogin"
                label="Barcode As Default Login"
                @change="checkBarcode"
              ></CustomCheck>
            </div>
            <span class="small ms-4"> (Barcode or QR code scanning) </span>
          </div>
          <div class="v3-module-list-checkbox d-flex mt-2">
            <CustomCheck
              v-model="state.form.hideStudentKioskPasscodeField"
              label="Hide Student Kiosk Passcode Field"
              @change="submit"
            ></CustomCheck>
          </div>
          <div class="d-flex flex-column">
            <div class="v3-module-list-checkbox d-flex mt-2">
              <CustomCheck
                v-model="state.form.studentNumberAsKioskPasscode"
                label="Set Kiosk Passcode to be Student Number"
                @change="openKioskPasscodeActionDialog"
                :key="setPasscodeCheckbox"
              ></CustomCheck>
            </div>
            <span class="small ms-4">
              (Student Number must be 4+ characters long.)</span
            >
          </div>
        </div>
        <p class="mt-2 text-start v3-module-list-title">
          Default Domain for Kiosk:
        </p>
        <CRow class="mt-2 mb-2">
          <CustomSelect
            v-model="state.form.defaultDomain"
            :options="schoolDomains"
            placeholder="Default Domain"
            :close-on-select="true"
            @update:model-value="submit"
          />
        </CRow>
        <CAlert
          v-if="state.reqResponse.message"
          class="mt-4"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../../HelpCenterButton"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import { useStore } from "vuex"
import { onMounted, computed, reactive, ref, inject, watch } from "vue"

export default {
  name: "KioskModuleForm",
  components: { HelpCenterButton, CustomCheck, CustomSwitch, CustomSelect },
  setup() {
    const store = useStore()

    const actionDialog = inject("actionDialog")

    const setPasscodeCheckbox = ref(0)

    const state = reactive({
      form: {
        urlKloginMethodOnly: false,
        cardAsDefaultLogin: false,
        barcodeAsDefaultLogin: false,
        hideStudentKioskPasscodeField: false,
        studentNumberAsKioskPasscode: false,
        defaultDomain: null
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: false
    })

    const isLoading = ref(false)

    const schoolDomains = computed(() => store.getters["schools/schoolDomains"])

    const currentUser = computed(() => store.getters["authentication/user"])

    const options = computed(() => {
      const index = store.state.schools.activeSchoolModules.findIndex(
        (module) => module.name === "Kiosk"
      )

      return index !== -1
        ? JSON.parse(
            store.state.schools.activeSchoolModules[index].pivot.option_json
          )
        : false
    })

    const active = computed(() => {
      const index = store.state.schools.activeSchoolModules.findIndex(
        (module) => module.name === "Kiosk"
      )

      return index !== -1
        ? Boolean(store.state.schools.activeSchoolModules[index].pivot.status)
        : false
    })

    const setFormData = () => {
      state.form.urlKloginMethodOnly = Boolean(options.value.kurl)
      state.form.cardAsDefaultLogin = Boolean(options.value.usbcard)
      state.form.barcodeAsDefaultLogin = Boolean(options.value.barcode)
      state.form.hideStudentKioskPasscodeField = Boolean(
        options.value.spasscode
      )
      state.form.studentNumberAsKioskPasscode = Boolean(
        options.value.prefill_kiosk_passcode
      )
      state.form.defaultDomain = options.value.default_domain
      state.form.defaultDomain = options.value.default_domain
        ? options.value.default_domain
        : schoolDomains.value[0]
        ? schoolDomains.value[0]
        : ""
    }

    onMounted(() => {
      getSchoolDomains()
      if (options.value) {
        setFormData()
      } else {
        state.isHidden = true
      }
      state.isActive = active.value
    })

    const getSchoolDomains = () => {
      store
        .dispatch("schools/getSchoolDomains", currentUser.value.school_id)
        .then(() => {
          setFormData()
        })
    }

    const checkBarcode = () => {
      state.form.cardAsDefaultLogin = false
      submit()
    }

    const checkCard = () => {
      state.form.barcodeAsDefaultLogin = false
      submit()
    }

    const submit = () => {
      setTimeout(() => {
        resetResponseMessages()
        const inputsData = JSON.stringify({
          kurl: state.form.urlKloginMethodOnly,
          usbcard: state.form.cardAsDefaultLogin,
          barcode: state.form.barcodeAsDefaultLogin,
          spasscode: state.form.hideStudentKioskPasscodeField,
          prefill_kiosk_passcode: state.form.studentNumberAsKioskPasscode,
          default_domain: state.form.defaultDomain
        })
        isLoading.value = true
        store
          .dispatch("modules/updateKioskModule", { option_json: inputsData })
          .then(() => {
            setSuccessResponse("Module updated.")
            getActiveModules()
            isLoading.value = false
            setTimeout(() => {
              resetResponseMessages()
            }, 850)
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            isLoading.value = false
            getActiveModules()
          })
      }, 100)
    }

    const getActiveModules = () => {
      store
        .dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
        .then(() => {
          setFormData()
        })
    }

    const openKioskPasscodeActionDialog = (value) => {
      if (value) {
        actionDialog.open(submit, {
          args: {},
          props: {
            question:
              "This will overwrite any other passcode that has already been created for all Kiosk users. Are you sure you want to proceed?",
            confirmText: "Yes, overwrite all"
          }
        })
      } else {
        submit()
      }
    }

    watch(
      () => actionDialog.isVisible,
      (value) => {
        if (!value && !actionDialog.isConfirmed) {
          state.form.studentNumberAsKioskPasscode = false
          // re-render checkbox
          setPasscodeCheckbox.value++
        }
      }
    )

    const toggleKiosk = (status) => {
      store
        .dispatch("modules/toggleModule", {
          id: 3,
          moduleStatus: { status: status ? 0 : 1 }
        })
        .then(() => {
          state.isActive = !state.isActive
          store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    return {
      state,
      schoolDomains,
      checkBarcode,
      checkCard,
      submit,
      openKioskPasscodeActionDialog,
      toggleKiosk,
      setPasscodeCheckbox
    }
  }
}
</script>

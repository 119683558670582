<template>
  <CContainer>
    <Loader :is-processing="state.isLoading" />
    <CRow class="d-flex justify-content-center p-5">
      <CCol
        col="12"
        sm="10"
        md="8"
        xl="6"
        class="v3kiosk-body max-w-620 m-auto p-4 text-center py-5"
      >
        <CRow class="kiosk-mode-top max-w-550 m-auto">
          <CCol class="text-start p-0">
            <img
              src="@/assets/images/securlypass-color-logo.png"
              alt="securlypass"
              class="w-75"
          /></CCol>
          <CCol class="kiosk-mode-text text-end p-0">
            <div>Kiosk Mode</div>
          </CCol>
        </CRow>
        <div class="max-w-550 m-auto">
          <div class="kiosk-login-auth-box text-center mt-3 mb-3">
            <div class="kiosk-login-auth-box-text">TEACHER AUTHENTICATION</div>
          </div>
          <div class="mb-4">
            <Label>Code</Label>
            <InputField
              data-test-id="kiosk-login-code"
              class="w-100"
              :invalid-feedback="errors.code"
              v-model="state.form.code"
            ></InputField>
          </div>
          <CRow
            v-if="state.reqResponse.message"
            class="max-w-550 m-auto mb-3 mt-3"
          >
            <InfoBox class="border-0 v3-info-box danger w-100"
              ><div
                class="v3-info-box-title v3-info-box-title-not-bold m-0 not-bold"
              >
                {{ state.reqResponse.message }}
              </div></InfoBox
            >
          </CRow>
          <div class="w-100" @click="login(null)">
            <LoadingButton
              data-test-id="kiosk-login-button"
              ref="kioskloading"
              :is-loading="state.isLoading"
              solid
              rounded
              class="w-100 d-flex align-items-center justify-content-center"
            >
              Submit
            </LoadingButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import Loader from "@/v3components/shared/Loader/Loader"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { computed, reactive } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "KioskLoginForm",
  components: {
    Loader,
    Label,
    InputField,
    InfoBox,
    LoadingButton
  },
  mounted() {
    // this.$route.query isn't available in setup() so we have to do this here
    if (this.$route.query.code) {
      this.login(this.$route.query.code)
    }
  },
  setup() {
    const store = useStore()

    const router = useRouter()

    const state = reactive({
      isLoading: false,
      form: {
        code: null
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        code: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const login = (code) => {
      state.form.code = code ? code : state.form.code
      resetResponseMessages()
      if (isValid.value) {
        state.isLoading = true
        store
          .dispatch("kiosks/directLogin", { passcode: state.form.code })
          .then((response) => {
            const data = response.data.data
            if (data) {
              store.commit("kiosks/SET_ACTIVE_KIOSK", data)
              if (data.favorites && data.favorites.length) {
                const fav = JSON.parse(JSON.stringify(data.favorites))
                const favs = fav.sort((a, b) => {
                  return a.position - b.position
                })
                store.commit("favorites/SET_FAVORITES", favs)
              }
            }
            localStorage.setItem("kioskDirectLoginToken", state.form.code)
            router.push("/kiosk/launch")
            resetForm()
            setSuccessResponse("Successfully Logged.")
            state.isLoading = false
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            state.isLoading = false
            state.form.code = null
          })
      } else {
        v$.value.$touch()
      }
    }

    const resetForm = () => {
      state.form = {
        code: null
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    return {
      state,
      login,
      errors,
      isValid
    }
  }
}
</script>

<template>
  <CContainer>
    <CRow class="justify-content-center mt-4">
      <CCol md="12" class="text-start">
        <CRow>
          <CCol class="d-flex">
            <div class="v3-module-list-title">Tardy Management</div>
            <HelpCenterButton
              classes="ms-2"
              content-key="module_tardy_management"
            />
          </CCol>
          <CCol class="d-flex flex-row-reverse align-items-center">
            <CustomSwitch
              type="radio"
              :model-value="state.isActive"
              @change="toggleTardy(state.isActive)"
            ></CustomSwitch>
          </CCol>
        </CRow>
        <div class="v3-module-list-box" v-if="state.isActive">
          <p class="text-start">Input Tardy Incidents:</p>
          <div class="ms-2">
            <div class="v3-module-list-checkbox d-flex mt-4">
              <CustomCheck
                v-model="state.form.usageCSV"
                label="Upload CSV file of tardy data"
                @change="submit"
              ></CustomCheck>
            </div>

            <p class="mt-3 text-start">TCH Pass:</p>

            <div class="v3-module-list-checkbox d-flex mt-4">
              <CustomCheck
                v-model="state.form.usageEHP"
                label="Allow Admins"
                @change="submit"
              ></CustomCheck>
            </div>

            <div class="v3-module-list-checkbox d-flex mt-2">
              <CustomCheck
                v-if="state.form.usageEHP"
                v-model="state.form.allowTeacherStaff"
                label="Allow all teachers/staff"
                @change="allowTeacherStaff"
              ></CustomCheck>
            </div>

            <div class="v3-module-list-checkbox d-flex mt-2">
              <CustomCheck
                v-if="state.form.usageEHP"
                v-model="state.form.allowSelectedUsersOnly"
                label="Only allow specific teachers/staff"
                @change="allowSelectedUsersOnly"
              ></CustomCheck>
            </div>
            <BaseButton
              v-if="state.form.allowSelectedUsersOnly && state.form.usageEHP"
              class="mt-2"
              @click="goToSelectAdultsPage"
              rounded
              >Add Adults</BaseButton
            >

            <p v-if="state.form.usageEHP" class="mt-3 text-start">
              Student Options:
            </p>

            <div v-if="state.form.usageEHP" class="d-flex mt-1">
              <p class="mt-3 text-start align-items-center">
                Allow Students to mark:
              </p>
              <div class="v3-module-list-checkbox d-flex ms-2">
                <CustomCheck
                  v-if="state.form.usageEHP"
                  v-model="state.form.allowStudentsLTC"
                  label="LTC"
                  @change="allowStudentsLTC"
                ></CustomCheck>
              </div>

              <div class="v3-module-list-checkbox d-flex ms-2">
                <CustomCheck
                  v-if="state.form.usageEHP"
                  v-model="state.form.allowStudentsLTS"
                  label="LTS"
                  @change="allowStudentsLTS"
                ></CustomCheck>
              </div>
              <p class="mt-3 text-start ms-3">Allow students to see ladder:</p>

              <div class="v3-module-list-checkbox d-flex ms-2">
                <CustomCheck
                  v-model="state.form.allowStudentsSeeLevel"
                  @change="submit"
                ></CustomCheck>
              </div>
            </div>
          </div>
        </div>
        <CAlert
          v-if="state.reqResponse.message"
          class="mt-4"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import HelpCenterButton from "../../HelpCenterButton"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import { useStore } from "vuex"
import { onMounted, computed, reactive, ref } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "TardyManagementModuleForm",
  components: { HelpCenterButton, BaseButton, CustomCheck, CustomSwitch },
  setup() {
    const store = useStore()

    const router = useRouter()

    const state = reactive({
      form: {
        allowTeacherStaff: false,
        allowSelectedUsersOnly: false,
        allowStudentsLTC: false,
        allowStudentsLTS: false,
        allowStudentsSeeLevel: false,
        usageCSV: false,
        usageEHP: false
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: false
    })

    const isLoading = ref(false)

    const schoolDomains = computed(() => store.getters["schools/schoolDomains"])
    const currentUser = computed(() => store.getters["authentication/user"])

    const options = computed(() => {
      const tardyModule = store.state.schools.activeSchoolModules.find(
        (module) => module.name === "Tardy Management"
      )
      return tardyModule ? JSON.parse(tardyModule.pivot.option_json) : false
    })

    const active = computed(() => {
      const tardyModule = store.state.schools.activeSchoolModules.find(
        (module) => module.name === "Tardy Management"
      )
      return tardyModule ? Boolean(tardyModule.pivot.status) : false
    })
    onMounted(() => {
      if (options.value) {
        setForm()
      }
      state.isActive = active.value
    })

    const setForm = () => {
      state.form.allowTeacherStaff = options.value["allow-teacher-staff"]
      state.form.allowSelectedUsersOnly =
        options.value["allow-selected-users-only"]
      state.form.allowStudentsLTS =
        options.value["allow-students-create"]["lts"]
      state.form.allowStudentsLTC =
        options.value["allow-students-create"]["ltc"]
      state.form.allowStudentsSeeLevel =
        options.value["allow-students-see-level"]
      state.form.usageCSV = options.value.usage["via-csv"]
      state.form.usageEHP = options.value.usage["within-ehp"]
    }

    const allowTeacherStaff = (value) => {
      state.form.allowTeacherStaff = value
      if (value == true) {
        state.form.allowSelectedUsersOnly = false
        submit()
      } else {
        submit()
      }
    }

    const allowStudentsLTC = (value) => {
      state.form.allowStudentsLTC = value
      if (value == true) {
        state.form.allowSelectedUsersOnly = false
        submit()
      } else {
        submit()
      }
    }

    const allowStudentsLTS = (value) => {
      state.form.allowStudentsLTS = value
      if (value == true) {
        state.form.allowSelectedUsersOnly = false
        submit()
      } else {
        submit()
      }
    }

    const allowSelectedUsersOnly = (value) => {
      state.form.allowSelectedUsersOnly = value
      if (value == true) {
        state.form.allowTeacherStaff = false
        state.form.allowStudents = false
        submit()
      } else {
        submit()
      }
    }

    const submit = () => {
      setTimeout(() => {
        resetResponseMessages()
        const inputsData = JSON.stringify({
          "allow-teacher-staff": state.form.allowTeacherStaff,
          "allow-selected-users-only": state.form.allowSelectedUsersOnly,
          "allow-students-create": {
            ltc: state.form.allowStudentsLTC,
            lts: state.form.allowStudentsLTS
          },
          "allow-students-see-level": state.form.allowStudentsSeeLevel,
          usage: {
            "via-csv": state.form.usageCSV,
            "within-ehp": state.form.usageEHP
          }
        })
        isLoading.value = true
        store
          .dispatch("modules/updateTardyModule", { option_json: inputsData })
          .then(() => {
            setSuccessResponse("Module updated.")
            store
              .dispatch("schools/getActiveModules", {
                clearCacheEntry: true
              })
              .then(() => {
                setForm()
              })
            isLoading.value = false
            setTimeout(() => {
              resetResponseMessages()
            }, 850)
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            isLoading.value = false
            store
              .dispatch("schools/getActiveModules", {
                clearCacheEntry: true
              })
              .then(() => {
                setForm()
              })
          })
      }, 100)
    }

    const toggleTardy = (status) => {
      store
        .dispatch("modules/toggleModule", {
          id: 5,
          moduleStatus: { status: status ? 0 : 1 }
        })
        .then(() => {
          state.isActive = !state.isActive
          store
            .dispatch("schools/getActiveModules", {
              clearCacheEntry: true
            })
            .then(() => {
              getExceededIncidents()
            })
        })
    }

    const getExceededIncidents = () => {
      state.isActive
        ? store.dispatch("exceededIncidents/getExceededIncidents")
        : store.commit("exceededIncidents/SET_EXCEEDED_INCIDENTS", false)
    }

    const goToSelectAdultsPage = () => {
      router.push("/tardy-management/adults")
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }
    return {
      state,
      schoolDomains,
      currentUser,
      setForm,
      allowTeacherStaff,
      allowStudentsLTC,
      allowStudentsLTS,
      allowSelectedUsersOnly,
      submit,
      toggleTardy,
      goToSelectAdultsPage
    }
  }
}
</script>

<template>
  <div>
    <div class="v3kiosk-active-pass">
      <CRow v-if="activePass" class="justify-content-center">
        <hr class="kiosk-horizontal-line mb-2" />
        <div class="v3kiosk-active-pass-title mb-2">
          <div v-if="activePass.approved_by">Your pass is approved.</div>
          <div v-else>
            {{ activePass.created_by_user.first_name }}
            {{ activePass.created_by_user.last_name }}, your pass is created and
            is awaiting approval from your teacher's dashboard or this Kiosk.
          </div>
        </div>

        <StatusBox
          v-if="activePass.approved_by"
          class="d-flex justify-content-center mb-2"
          title="active"
          title-color="#ffffff"
          background-color="#008566"
          icon="ri-checkbox-circle-line"
          icon-color="#ffffff"
        />
        <StatusBox
          v-if="!activePass.approved_by"
          class="d-flex justify-content-center mb-2"
          title="waiting approval"
          title-color="#ffffff"
          background-color="#308DEB"
          icon="ri-loader-fill"
          icon-color="#ffffff"
        ></StatusBox>

        <div>
          {{ activePass.flags.auto_pass.is_valid ? "Auto Pass" : "" }}
        </div>

        <CCol class="v3kiosk-active-pass-table mb-2">
          <CRow class="v3kiosk-active-pass-table-header mb-1">
            <CCol class="v3kiosk-active-pass-table-header-info text-start ms-1">
              <div class="created-at">
                {{
                  $helpers.transformDate(
                    activePass.created_at,
                    "dddd, DD, MMMM, YYYY"
                  )
                }}
              </div>
              <div class="time">
                Time
                {{ $helpers.transformDate(activePass.created_at, "h:mm a") }}
              </div>
            </CCol>
            <CCol
              class="v3kiosk-active-pass-table-header-pass-history align-self-center text-end me-1"
              @click="showPassHistory(passHistories)"
            >
              {{ passHistories.length }} <i class="ri-door-open-line" />
            </CCol>
          </CRow>
          <CRow class="v3kiosk-active-pass-table-content">
            <CCol class="v3kiosk-active-pass-table-content-text">
              {{ activePass.from ? activePass.from.name : "" }}
            </CCol>
            <CCol class="v3kiosk-active-pass-table-content-icon">
              <i class="ri-arrow-go-back-line" />
            </CCol>
            <CCol class="v3kiosk-active-pass-table-content-text">
              {{ activePass.to ? activePass.to.name : "" }}
            </CCol>
          </CRow>
          <CRow class="v3kiosk-active-pass-table-bottom mt-1">
            <CCol
              class="v3kiosk-active-pass-table-bottom-type d-flex flex-column justify-content-center align-content-center ms-1"
            >
              <div class="v3kiosk-active-pass-table-bottom-type-box">
                <div class="icon align-self-center">
                  <i class="ri-device-line" />
                </div>
                <div class="text">KSK</div>
              </div>
            </CCol>
            <!-- This will be used in future -->
            <!-- <CCol
              class="v3kiosk-active-pass-table-bottom-comments text-end align-self-center me-1"
            >
              <div>
                <span class="comment-count me-1">0</span>
                <i class="ri-chat-new-line" />
              </div>
            </CCol> -->
          </CRow>
        </CCol>

        <CRow
          v-if="
            (!activePass.approved_by && !isAutoPass) ||
            (activePass.approved_by == null &&
              activePass.flags.auto_pass.is_valid == false) ||
            (!activePass.approved_by &&
              activePass.flags.auto_pass &&
              activePass.flags.auto_pass.mode == 'stop_only')
          "
          class="v3kiosk-active-pass-staff-box my-2"
        >
          <div class="v3kiosk-active-pass-staff-box-text">Staff use only</div>
        </CRow>

        <div
          v-if="
            (!activePass.approved_by && !isAutoPass) ||
            (activePass.approved_by == null &&
              activePass.flags.auto_pass.is_valid == false) ||
            (!activePass.approved_by &&
              activePass.flags.auto_pass &&
              activePass.flags.auto_pass.mode == 'stop_only')
          "
          class="mt-2 mb-2"
        >
          <Label>Enter your PIN</Label>
          <InputField
            class="w-100"
            v-model="state.teacherPin"
            :invalid-feedback="errors.teacherPin"
            :v-no-auto-fill-input="true"
            :autocomplete="false"
            input-type="password"
            @update:model-value="state.responseMessageTeacherApproval = null"
          >
          </InputField>

          <InfoBox
            v-if="
              state.reqResponse &&
              state.reqResponse.message &&
              state.reqResponse.message !=
                'Submission failed, please try again.'
            "
            class="border-0 v3-info-box danger w-100 my-3"
            ><div
              class="v3-info-box-title v3-info-box-title-not-bold m-0 not-bold"
            >
              {{ state.reqResponse.message }}
            </div></InfoBox
          >

          <InfoBox
            v-if="
              state.reqResponse &&
              state.reqResponse.errors &&
              state.reqResponse.errors.teacher_pin
            "
            class="border-0 v3-info-box danger w-100 my-3"
            ><div
              class="v3-info-box-title v3-info-box-title-not-bold m-0 not-bold"
            >
              {{ state.reqResponse.errors.teacher_pin[0] }}
            </div></InfoBox
          >
          <CRow class="d-flex justify-content-center mt-3">
            <CCol v-if="!activePass.approved_at">
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                rounded
                @click="cancelPass()"
              >
                Cancel
              </BaseButton>
            </CCol>
            <CCol>
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                solid-gray
                rounded
                @click="cancel()"
              >
                Close({{ timeoutCounter }})
              </BaseButton>
            </CCol>
            <CCol v-if="currentStatus == 'waiting' && !activePass.parent">
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                rounded
                solid-gradient
                @click="updatePassStuff(state.actions.approved)"
              >
                Approve
              </BaseButton>
            </CCol>
          </CRow>
        </div>
        <div v-else>
          <CRow class="d-flex justify-content-center mt-3">
            <CCol v-if="!activePass.approved_at">
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                rounded
                @click="cancelPass()"
              >
                Cancel
              </BaseButton>
            </CCol>
            <CCol>
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                rounded
                solid-gray
                @click="cancel()"
              >
                Close({{ timeoutCounter }})
              </BaseButton>
            </CCol>
            <CCol v-if="isAutoPass && !activePass.approved_at">
              <BaseButton
                class="w-100 d-flex align-items-center justify-content-center"
                rounded
                solid
                custom-color="#00b973"
                @click="autoApprovePass()"
              >
                Start
              </BaseButton>
            </CCol>
          </CRow>
        </div>
      </CRow>
      <Loader
        :is-processing="!activePass || state.isLoading"
        classes="position-absolute"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import StatusBox from "@/v3components/shared/Alerts/StatusBox.vue"
import Loader from "@/v3components/shared/Loader/Loader"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import BaseButton from "@/v3components/shared/Buttons/BaseButton"
import StudentPassHistory from "@/v3components/Forms/Students/StudentPassHistory"
import Echo from "laravel-echo"
import { useStore } from "vuex"
import { reactive, computed, onMounted, inject, watch } from "vue"
let EchoInstance = null

export default {
  name: "KioskActivePass",
  components: {
    Loader,
    StatusBox,
    Label,
    InputField,
    InfoBox,
    BaseButton
  },
  emits: ["cancel"],
  setup(args, { emit }) {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      interval: null,
      timeout: 120,
      visible: false,
      passStatuses: {
        waiting: "Waiting Approval",
        approved: "Active",
        expired: "Ended",
        canceled: "Canceled"
      },
      actions: {
        approved: 1,
        end: 2,
        arrived: 3,
        returning: 4,
        cancel: 5
      },
      counter: 1,
      teacherPin: "",
      responseMessageTeacherApproval: null,
      responseMessageRoomApproval: null,
      isLoading: false,
      updatePassHistory: true,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      teacherPin: {
        required: helpers.withMessage(validationMessages.required, required)
      }
    }
    const v$ = useVuelidate(validations, state)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const activePass = computed(() => store.getters["kiosks/activePass"])
    const passHistories = computed(() => store.getters["kiosks/passHistories"])
    const activeKiosk = computed(() => store.getters["kiosks/activeKiosk"])
    const passLimit = computed(() => store.getters["passes/pass_limit"])

    const timeInPass = computed(() => {
      return state.counter && activePass.value
        ? $helpers.secondsToTimeString(
            moment().diff(
              activePass.value.parent && activePass.value.parent.approved_at
                ? moment(activePass.value.parent.approved_at)
                : activePass.value.approved_at
                  ? moment(activePass.value.approved_at)
                  : moment(),
              "seconds"
            )
          )
        : ""
    })

    const currentStatus = computed(() => {
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at === null &&
        !activePass.value.parent
      ) {
        return "waiting"
      }
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at === null &&
        activePass.value.parent
      ) {
        return "approved"
      }
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at !== null &&
        activePass.value.completed_at === null
      ) {
        return "approved"
      }
      return "expired"
    })

    const isLayoverPass = computed(() => {
      return activePass.value &&
        (activePass.value.to_type == "App\\Models\\User" ||
          (activePass.value.to && activePass.value.to.trip_type == "Layover"))
        ? true
        : false
    })

    const timeoutCounter = computed(() => {
      return moment.utc(state.timeout * 1000).format("mm:ss")
    })

    const isAutoPass = computed(() => {
      return (
        activePass.value &&
        activePass.value.flags &&
        activePass.value.flags.auto_pass &&
        activePass.value.flags.auto_pass.is_valid
      )
    })

    onMounted(() => {
      initTimeout()
      initSockets()
      state.visible = true
    })

    const cancelPass = () => {
      if (window.confirm("Are you sure you want to cancel this pass?")) {
        state.isLoading = true
        resetResponseMessages()
        if (activePass.value) {
          const data = {
            id: activePass.value.id,
            data: {
              action: 8,
              kiosk_id: activeKiosk.value.id
            }
          }
          store
            .dispatch("kiosks/updatePass", data)
            .then(() => {
              state.isLoading = false
              setSuccessResponse("Your pass has successfully been ended.")
              setTimeout(() => {
                resetResponseMessages()
                cancel()
              }, 1000)
            })
            .catch((err) => {
              const response = err.response.data
              setErrorResponse(response.message, response.errors)
              state.isLoading = false
            })
        }
      }
    }

    const updatePassStuff = (argAction, returnType) => {
      resetResponseMessages()
      if (isValid.value) {
        state.isLoading = true
        state.updatePassHistory = false
        const elem = {
          id: activePass.value.id,
          data: {
            teacher_pin: state.teacherPin,
            approval_message: state.teacherNote,
            action: argAction,
            kiosk_id: activeKiosk.value.id
          }
        }
        if (returnType) {
          elem.data.return_type = returnType
        }

        store
          .dispatch("kiosks/updatePass", elem)
          .then((res) => {
            store.commit("kiosks/SET_ACTIVE_PASS", res.data.data)
            state.isLoading = false
            setSuccessResponse("Your pass has successfully been approved.")
            setTimeout(() => {
              resetResponseMessages()
              v$.value.$reset()
              // logOutUser();
            }, 1000)
          })
          .catch((err) => {
            if (err && err.response && err.response.status == 401) {
              cancel()
            }
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const autoApprovePass = () => {
      state.isLoading = true
      const data = {
        passId: activePass.value.id,
        action: 1,
        kiosk_id: activeKiosk.value.id
      }
      store
        .dispatch("kiosks/autoApprovePass", data)
        .then((res) => {
          store.commit("kiosks/SET_ACTIVE_PASS", res.data.data)
          state.isLoading = false
          setSuccessResponse("Your pass has successfully been approved.")
          setTimeout(() => {
            resetResponseMessages()
            cancel()
          }, 1200)
        })
        .catch((err) => {
          if (err && err.response && err.response.status == 401) {
            cancel()
          }
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
        })
    }

    const initTimeout = () => {
      if (!state.interval) {
        state.interval = setInterval(() => {
          if (state.timeout > 0) {
            state.timeout--
          } else {
            clearInterval(state.interval)
            cancel()
          }
        }, 1000)
      }
    }

    const initSockets = () => {
      if (process.env.VUE_APP_SOCKET_PROVIDER == "PUSHER") {
        window.Pusher = require("pusher-js")
        EchoInstance = new Echo({
          broadcaster: "pusher",
          authEndpoint: `${process.env.VUE_APP_AUTH_URL}/broadcasting/auth`,
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          auth: {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("kioskAccessToken")
            }
          },
          forceTLS: true
        })
      } else {
        window.io = require("socket.io-client")
        EchoInstance = new Echo({
          broadcaster: "socket.io",
          host: process.env.VUE_APP_WS_HOST,
          authEndpoint: `${process.env.VUE_APP_AUTH_URL}/broadcasting/auth`,
          encrypted: process.env.VUE_APP_WS_ENCRYPTED === "true",
          auth: {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("kioskAccessToken")
            }
          },
          transports: ["websocket"]
        })
      }
      window.kioskChannel = EchoInstance.private(
        `kiosks.user.${activePass.value.user_id}`
      )
      window.kioskChannel.listen("PassApproved", (payload) => {
        if (payload && payload.pass) {
          store.commit("kiosks/SET_ACTIVE_PASS", payload.pass)
        }
      })
    }

    const destroySockets = () => {
      if (process.env.VUE_APP_SOCKET_PROVIDER == "PUSHER") {
        window.kioskChannel.subscription.unbind_all()
      } else {
        window.kioskChannel.unsubscribe()
      }
      EchoInstance.disconnect()
    }

    const cancel = () => {
      destroySockets()
      clearInterval(state.interval)
      emit("cancel")
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    watch(currentStatus, (newStatus) => {
      if (newStatus === "expired") {
        cancel()
      }
    })

    const showPassHistory = (passHistoryOptions) => {
      modal.open(StudentPassHistory, {
        size: "lg",
        title: "Today's history",
        props: {
          options: passHistoryOptions
        },
        help: "todays_history"
      })
    }

    return {
      state,
      activePass,
      passHistories,
      activeKiosk,
      passLimit,
      timeInPass,
      currentStatus,
      isLayoverPass,
      timeoutCounter,
      isAutoPass,
      isValid,
      errors,
      cancelPass,
      updatePassStuff,
      autoApprovePass,
      initTimeout,
      initSockets,
      destroySockets,
      cancel,
      showPassHistory
    }
  }
}
</script>

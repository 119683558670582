<template>
  <div data-test-id="create-kiosk-form">
    <Loader :is-processing="state.isLoading" />
    <hr class="kiosk-horizontal-line mb-2" />
    <div v-if="createMode">
      <div class="kiosk-pass">
        <div class="kiosk-pass-title text-center mb-3">New Pass</div>
        <div class="mb-2">
          <div v-if="activeKiosk" class="kiosk-pass-from text-start m-0">
            Departing From:
            <span class="bolder">{{
              activeKiosk.room
                ? activeKiosk.room.name
                : activeKiosk.user
                  ? activeKiosk.user.name
                  : ""
            }}</span>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <Label> Destination </Label>
        <LazyLoadSelect
          data-test-id="kiosk-pass-destination"
          :context="
            activeKiosk && activeKiosk.school
              ? activeKiosk.school.context
              : null
          "
          :invalid-feedback="errors.to"
          :is-multiple="false"
          :selected="state.form.to"
          type="kiosk"
          :adult-pass-request-disabled="true"
          :is-in-kiosk="true"
          :refresh-on-open="true"
          placeholder="Select one"
          @changed="
            (value) => {
              checkUnavailable(value)
            }
          "
        >
        </LazyLoadSelect>
      </div>

      <InfoBox v-if="destinationUnavailability" class="mb-2 danger"
        >{{
          destinationUnavailability.unavailable_type == "App\\Models\\Room"
            ? "This room is unavailable until"
            : "This adult is unavailable until"
        }}
        <span
          class="mx-1"
          v-html="
            $helpers.getHTMLDateTime(
              destinationUnavailability.to_date,
              'MMMM D, YYYY',
              'h:mm A'
            ) + '.'
          "
        />
        <span v-if="destinationUnavailability.comment"
          >Note: {{ destinationUnavailability.comment }}</span
        ></InfoBox
      >

      <div
        v-if="favorites && favorites.length"
        class="kiosk-favorites-text text-start"
      >
        or quick select from
        <span class="kiosk-favorites-text-bolder">Favorites</span>
      </div>

      <FavoriteCarousel
        v-if="favorites && favorites.length"
        :teacher="true"
        :key="'destination'"
        :is-editable="false"
        :not-managable="true"
        :action="
          (elem) => {
            state.form.to = elem
            getFavorites()
          }
        "
        class="mb-3"
        section="destination"
        :selected="state.form.to"
        :options="favorites"
        :is-global="true"
      />

      <div
        v-if="
          state.form &&
          state.form.to &&
          state.form.to.value &&
          state.form.to.value.comment_type != 'Hidden'
        "
        class="mb-3"
      >
        <Label> Reason </Label>
        <InputField
          data-test-id="kiosk-pass-comment"
          v-model="state.form.reason"
          :invalid-feedback="errors.reason"
          max-chars="250"
          :rows="4"
          class="w-full"
          type="textarea"
          :placeholder="
            state.form.to.value.comment_type == 'Optional'
              ? 'Reason for pass (optional)'
              : 'Reason for pass (required)'
          "
        />
      </div>
      <div v-if="isTardyCreateAllow.ltc || isTardyCreateAllow.lts">
        <TardyRadioGroup
          :is-allowed-lates="true"
          :allowed-lates="isTardyCreateAllow"
          v-model="state.form.tardy_badge"
          class="mb-3"
        />
      </div>
      <div class="d-flex justify-content-center gap-3">
        <BaseButton
          class="w-50 d-flex align-items-center justify-content-center"
          rounded
          @click="cancel()"
        >
          Cancel
        </BaseButton>
        <BaseButton
          data-test-id="create-kiosk-pass-form-submit"
          class="w-50 d-flex align-items-center justify-content-center"
          rounded
          solid
          @click="createPass()"
        >
          Submit
        </BaseButton>
      </div>
    </div>
    <div
      v-else
      :class="{ 'opacity-0': !activePass && !state.reqResponse.message }"
      class="px-3 py-5"
    >
      <div class="pass-form-red m-0">
        <span class="flaticon-warning-1" />
      </div>
      <h2 v-if="activePass">The student already has an active pass.</h2>
      <h2 data-test-id="create-kiosk-info-box" v-if="state.reqResponse.message">
        {{ state.reqResponse.message }}
      </h2>
      <div v-if="state.reqResponse.errors" class="text-red">
        <div v-for="(err, index) in state.reqResponse.errors" :key="index">
          {{ err[0] }}
        </div>
      </div>
      <p class="mb-0 mt-3">
        This screen will be closed in {{ state.timeout }} seconds.
      </p>
      <p class="mt-0 px-1 cursor-pointer" @click="cancel()">
        (or click here to close it now)
      </p>
    </div>
  </div>
</template>

<script>
import FavoriteCarousel from "@/v3components/FavoriteCarousel.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import Loader from "@/v3components/shared/Loader/Loader"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import BaseButton from "@/v3components/shared/Buttons/BaseButton"
import { useStore } from "vuex"
import { onMounted, computed, reactive, onUnmounted, watch } from "vue"
import useVuelidate from "@vuelidate/core"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroup"

export default {
  name: "CreatePassForm",
  components: {
    FavoriteCarousel,
    LazyLoadSelect,
    Loader,
    Label,
    InputField,
    BaseButton,
    TardyRadioGroup,
    InfoBox
  },
  emits: ["kioskPassCreated", "tabChanged"],
  setup(args, { emit }) {
    const store = useStore()

    const state = reactive({
      interval: null,
      timeout: 60,
      isLoading: false,
      favoritesCollection: null,
      form: {
        to: [],
        reason: "",
        tardy_badge: ""
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const activeKiosk = computed(() => store.getters["kiosks/activeKiosk"])
    const access_token = computed(() => store.getters["kiosks/accessToken"])
    const activePass = computed(() => store.getters["kiosks/activePass"])
    const createNewPassData = computed(
      () => store.getters["kiosks/createNewPassData"]
    )

    const isTardyCreateAllow = computed(() => {
      return activeKiosk.value.allowed_late_passes
    })

    const validationMessages = {
      required: "This field is required",
      notSameAsFrom: "Please select a different teacher/location."
    }

    const validations = {
      form: {
        from: {},
        to: {
          required: helpers.withMessage(validationMessages.required, required),
          notSameAsFrom: helpers.withMessage(
            validationMessages.notSameAsFrom,
            (value) => {
              if (value)
                return !(
                  state.form.from.id == state.form.to.value.id &&
                  state.form.from.type == state.form.to.value.type
                )
            }
          ),
          available: (value) => {
            if (value) {
              return !destinationUnavailability.value
            }
          }
        },
        reason: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              if (state.form.to && state.form.to.value) {
                return state.form.to.value.comment_type == "Optional"
                  ? false
                  : state.form.to.value.comment_type == "Hidden"
                    ? false
                    : true
              }
            })
          )
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const createMode = computed(
      () => !activePass.value && !createNewPassData.value
    )

    const fromObj = computed(() => {
      return {
        type: activeKiosk.value.room_id
          ? "App\\Models\\Room"
          : "App\\Models\\User",
        id: activeKiosk.value.room_id
          ? activeKiosk.value.room_id
          : activeKiosk.value.user_id
            ? activeKiosk.value.user_id
            : ""
      }
    })

    const favorites = computed(() => {
      return state.favoritesCollection || activeKiosk.value.favorites
    })

    const destinationUnavailability = computed(() => {
      return state.form.to && state.form.to.value
        ? state.form.to.value.unavailability
        : null
    })

    onMounted(() => {
      if (!createMode.value) {
        state.interval = setInterval(() => {
          if (state.timeout > 0) {
            state.timeout--
          } else {
            clearInterval(state.interval)
            cancel()
          }
        }, 1000)
      }
      state.form.from = activeKiosk.value.room_id
        ? { id: activeKiosk.value.room_id, type: "App\\Models\\Room" }
        : activeKiosk.value.user_id
          ? { id: activeKiosk.value.user_id, type: "App\\Models\\User" }
          : ""
    })

    onUnmounted(() => {
      clearInterval(state.interval)
    })

    const createPass = (data) => {
      resetResponseMessages()
      if (isValid.value || data) {
        state.isLoading = true
        const pass = data
          ? data
          : {
              from_type: fromObj.value.type,
              from_id: fromObj.value.id,
              to_id: state.form.to.value.id,
              to_type: state.form.to.value.type,
              comment: state.form.reason,
              kiosk_id: activeKiosk.value.id,
              kiosk_passcode: activeKiosk.value.passcode,
              tardy_badge: state.form.tardy_badge
            }
        if (access_token.value) {
          store
            .dispatch("kiosks/createKioskPass", pass)
            .then((response) => {
              const data = response.data
              setTimeout(() => {
                resetResponseMessages()
                emit("kioskPassCreated")
                store.commit("kiosks/SET_CREATE_NEW_PASS_DATA", null)
                store.commit("kiosks/SET_ACTIVE_PASS", data.pass)
                store.commit("passes/SET_PASS_LIMIT", data.pass_limit)
                getPassHistories()
              }, 100)
              setSuccessResponse(data.message)
            })
            .catch((err) => {
              if (err && err.response) {
                const response = err.response.data
                setErrorResponse(response.message, response.errors)
              }
              state.isLoading = false
            })
        } else {
          store.commit("kiosks/SET_CREATE_NEW_PASS_DATA", pass)
          emit("tabChanged", "userLogin")
        }
      } else {
        v$.value.$touch()
      }
    }

    const getPassHistories = () => {
      store.dispatch("kiosks/getPassHistories").then((res) => {
        const data = res.data
        if (data && data.data) {
          store.commit("kiosks/SET_PASS_HISTORIES", data.data)
        }
      })
    }

    const getFavorites = () => {
      store
        .dispatch("kiosks/getFavorites", activeKiosk.value.school.context)
        .then((response) => {
          if (response.data.data && response.data.data.length) {
            state.favoritesCollection = response.data.data
          }
        })
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }
    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const cancel = () => {
      emit("tabChanged", "mainScreen")
      store.dispatch("kiosks/userLogout")
    }

    watch(
      () => isTardyCreateAllow.value,
      (newVal) => {
        if (!newVal.ltc && state.form.tardy_badge == "LTC") {
          state.form.tardy_badge = ""
        }
        if (!newVal.lts && state.form.tardy_badge == "LTS") {
          state.form.tardy_badge = ""
        }
      }
    )

    const checkUnavailable = (value) => {
      state.form.to = value
      store
        .dispatch("rooms/getUnavailability", {
          id: value.value.id,
          type: value.value.type
        })
        .then((response) => {
          if (response.data.data) {
            state.form.to.value.unavailability = response.data.data
          }
        })
    }

    return {
      state,
      activeKiosk,
      access_token,
      activePass,
      isTardyCreateAllow,
      createNewPassData,
      createMode,
      fromObj,
      favorites,
      destinationUnavailability,
      createPass,
      cancel,
      getFavorites,
      errors,
      checkUnavailable
    }
  }
}
</script>
